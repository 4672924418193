<template>
	<div class="app-view">
		<van-nav-bar title="修改昵称" left-text="返回" left-arrow @click-left="$routerGo(-1)" @click-right="saveNickname" right-text="保存" />
		<van-cell-group>
            <van-field v-model="nickname" label="昵称" placeholder="请输入昵称" clearable />
        </van-cell-group>
	</div>
</template>
<script>
export default {
	data() {
		return {
            nickname: '',
		}
	},
	created(){
		this.nickname = this.$store.state.user.userInfo.nickname || ''
	},
    methods:{
        saveNickname() {
            if(this.nickname==''){
                this.$toast('昵称不能为空')
                return false;
            }
            this.$ask.post('/updateUserInfo', {nickname: this.nickname})
                .then(res => {
                    this.$store.dispatch('login',res.data)
                    this.$toast('修改成功')
                })
                .catch(this.$toastError)
        }
    }
}
</script>
